import { defineStore } from 'pinia'
import { WAITING, ACCEPTED, REGISTERED } from 'abcreche-constants/src/const/registrations/statuses'
import dayjs from 'dayjs'
import RegistrationRepository from '~/repositories/Creche/RegistrationRepository.js'

export const useRegistrationsStore = defineStore('registrations', {
    state: () => ({
        filters: {
            onlyWithoutCreche: null,
            onlyWithCreche: null,
            creche_types: [],
            creche_uuids: [],
            search: '',
            status: [WAITING, ACCEPTED]
        },
        registeredRegistrations: []
    }),

    actions: {
        setFilter ({ key, value }) {
            this.filters[key] = value
        },

        fetchRegisteredRegistrations () {
            if (this.registeredRegistrations.length > 0) {
                return Promise.resolve(this.registeredRegistrations)
            }

            return RegistrationRepository.index({
                status: REGISTERED
            })
                .then((response) => {
                    this.registeredRegistrations = response.data
                        .map((registration) => {
                            const endDate = registration.unregistered_at || registration.should_unregistered_at
                            registration.isFuture = dayjs().diff(registration.should_registered_at, 'days') < 0
                            registration.isPast = dayjs().diff(endDate, 'days') > 0
                            return registration
                        })
                        .sort((a, b) => a.isFuture - b.isFuture)
                })
        }
    }
})
